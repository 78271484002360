<template>
	<div id="login">
		<a target="_blank" href="./viewShow.html" class="link_to_view">发车时刻表</a>
		<jy-login :bg="loginBg" :logo="loginLogo" topName="智慧公交综合云服务平台" name="智能调度系统" @login="login"></jy-login>
	</div>
</template>
<script type="text/javascript">
import loginBg from "@/assets/login_bg.png";
// import loginLogo from '@/assets/login_logo.png'
import loginLogo from "@/assets/crrc_logo.png";
export default {
	data() {
		return {
			loginBg,
			loginLogo
		};
	},
	methods: {
		login(val) {
			let option = {
				userNa: val.userName,
				passwd: val.password
			};
			let url = "/login";
			this.$http.post(url, option, { isRequestParam: false }).then(data => {
				console.log(data);
				this.$util.setStor("token", data.detail.token);
				localStorage.setItem("orgNa", data.detail.orgNa);
				this.$message({
					message: "登录成功",
					type: "success"
				});
				this.$store.commit("setInfo", data.detail);
				console.log(this.$store.state.info);
				setTimeout(() => {
					this.$router.push({ name: "admin" });
				}, 300);
			});
		}
	}
};
</script>
<style type="text/css" lang="scss">
.link_to_view {
	display: inline-block;
	position: fixed;
	top: 20px;
	right: 20px;
	cursor: pointer;
	font-size: 16px;
	z-index: 1000;
	color: $buttonbackgroud;
}
</style>
